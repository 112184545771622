<template>
  <div>
    <p
      @click="toggleFilter"
      class="text-capitalize subtitle-2 primary-color hover-primary text-right"
    >
      {{
        showFilter
          ? "Sembunyikan Form Pencarian Pegawai"
          : "Lihat Form Pencarian Pegawai"
      }}
    </p>
    <v-card flat class="pa-5 mb-4" id="card-custom" v-if="showFilter">
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-text-field
            placeholder="Masukan Nama Pegawai"
            v-model="formInput"
            outlined
            dense
            hide-details
            @click="$refs.employeeSearch.change(true)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                placeholder="Bulan"
                append-icon="event"
                outlined
                dense
                hide-details
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              locale="id"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="my-0">
          <v-btn color="#FBB005" class="px-10" @click="onSearch">
            <span class="subtitle-2 text-capitalize">Cari</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <EmployeeSearchDialog
      ref="employeeSearch"
      :lists="[form]"
      value="NIP"
      @handle-pick="pickItem"
      singlePick
    />
  </div>
</template>
<script>
const EmployeeSearchDialog = () => import("@/components/Dialog/EmployeeSearch");
export default {
  components: {
    EmployeeSearchDialog
  },
  props: {
    prefill: { type: Object, required: false, default: null }
  },
  data() {
    return {
      showFilter: true,
      menu: false,
      date: null,
      search: null,
      form: {
        NIP: null,
        name: null
      }
    };
  },
  computed: {
    formInput() {
      if (this.form.NIP == null) return;
      return `${this.form.NIP} - ${this.form.name}`;
    }
  },
  methods: {
    populate(item) {
      this.date = item.date;
    },
    pickItem(value) {
      this.form.NIP = value.NIP;
      this.form.name = value.nama;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    onSearch() {
      this.getToPage(
        "presensi.detail",
        {
          params: { employeeId: this.form.NIP },
          query: { dateMonth: this.date }
        },
        "replace"
      );
      this.$emit("on-refresh", {
        employeeId: this.form.NIP,
        dateMonth: this.date
      });
    }
  },
  mounted() {
    if (this.prefill) {
      for (const [key, value] of Object.entries(this.prefill)) {
        this[key] = value;
      }
    }
  }
};
</script>
<style>
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
